import React, { useState, useEffect } from "react";
import { Page, Frame, Layout, FormLayout, Card, TextField, Form, Checkbox, Toast, Image, Collapsible, Button, Spinner } from "@shopify/polaris";
import { DiscordIcon } from "../assets";

export default function Integrations() {

    const storeDomain = new URL(window.location).searchParams.get("shop");
    const [saving, setSaving] = useState(false);
    const [enabledApp, setEnabledApp] = useState(false);
    const [guildId, setGuildId] = useState('');
    const [botToken, setBotToken] = useState('');
    const [createMember, setCreateMember] = useState(false);
    const [restrictMember, setRestrictMember] = useState(false);
    const [addRoletoMember, setAddRoletoMember] = useState('');
    const [assignRoleAsTags, setAssignRoleAsTags] = useState(false);
    const [assignTagsAsRole, setAssignTagsAsRole] = useState(false);
    const [removeRoleOnExpire, setRemoveRoleOnExpire] = useState(false);
    const [assignRolesToExistingOrders, setAssignRolesToExistingOrders] = useState(false);
    const [assignRolesToOrdersAmount, setAssignRolesToOrdersAmount] = useState(false);

    const [enableLinkButton, setEnableLinkButton] = useState(false);
    const [elementIdLinkButton, setElementIdLinkButton] = useState('');
    const [nameLinkButton, setNameLinkButton] = useState("Link with Discord");
    const [heightLinkButton, setHeightLinkButton] = useState(50);
    const [widthLinkButton, setWidthLinkButton] = useState(200);
    const [curveLinkButton, setCurveLinkButton] = useState(10);
    const [marginLinkButton, setMarginLinkButton] = useState(15);
    const [disableLinkButtonLogin, setDisableLinkButtonLogin] = useState(false);
    const [discordEmailLink, setDiscordEmailLink] = useState(false);

    const [enableInvite, setEnableInvite] = useState(false);
    const [inviteLink, setInviteLink] = useState('');
    const [elementIdInvite, setElementIdInvite] = useState('');
    const [nameInviteButton, setNameInviteButton] = useState("Join our Discord");
    const [heightInviteButton, setHeightInviteButton] = useState(50);
    const [widthInviteButton, setWidthInviteButton] = useState(200);
    const [curveInviteButton, setCurveInviteButton] = useState(10);
    const [marginInviteButton, setMarginInviteButton] = useState(15);
    const [copiedLink, setCopiedLink] = useState(false);
    const [copiedInvite, setCopiedInvite] = useState(false);
    const [active, setActive] = useState(false);
    
    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(`/api/get_data?shop=${storeDomain}`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'x-shopify-shop-domain': storeDomain
                    },
                });
                if (response.ok) {
                    const data = await response.json();
                    if (data.integrations.discord_integrations) {
                        const storeData = data.integrations.discord_integrations;
                        setEnabledApp(storeData.enabledApp);
                        setGuildId(storeData.guildId);
                        setBotToken(storeData.botToken);
                        setCreateMember(storeData.createMember);
                        setRestrictMember(storeData.restrictMember);
                        setAddRoletoMember(storeData.addRoletoMember);
                        setAssignRoleAsTags(storeData.assignRoleAsTags);
                        setAssignTagsAsRole(storeData.assignTagsAsRole);
                        setRemoveRoleOnExpire(storeData.removeRoleOnExpire);
                        setAssignRolesToExistingOrders(storeData.assignRolesToExistingOrders);
                        setAssignRolesToOrdersAmount(storeData.assignRolesToOrdersAmount);
                    }
                    if (data.integrations.discord_linked) {
                        const storeData = data.integrations.discord_linked;
                        setEnableLinkButton(storeData.enableLinkButton);
                        setElementIdLinkButton(storeData.elementIdLinkButton);
                        setNameLinkButton(storeData.nameLinkButton);
                        setHeightLinkButton(storeData.heightLinkButton);
                        setWidthLinkButton(storeData.widthLinkButton);
                        setCurveLinkButton(storeData.curveLinkButton);
                        setMarginLinkButton(storeData.marginLinkButton);
                        setDisableLinkButtonLogin(storeData.disableLinkButtonLogin);
                        setDiscordEmailLink(storeData.discordEmailLink);
                    }
                    if (data.integrations.discord_invite) {
                        const storeData1 = data.integrations.discord_invite;
                        setEnableInvite(storeData1.enableInvite);
                        setInviteLink(storeData1.inviteLink);
                        setElementIdInvite(storeData1.elementIdInvite);
                        setNameInviteButton(storeData1.nameInviteButton);
                        setHeightInviteButton(storeData1.heightInviteButton);
                        setWidthInviteButton(storeData1.widthInviteButton);
                        setCurveInviteButton(storeData1.curveInviteButton);
                        setMarginInviteButton(storeData1.marginInviteButton);
                    }
                    console.log(response.status);
                } else {
                    console.error(response.status);
                }
            } catch (error) {
                console.error(error);
            }
        };
        fetchData();
    }, [storeDomain]);

    const handleDismiss = async () => {
        setActive((active) => !active);
    };

    const handleSubmit = async () =>  {

        setSaving(true);
        const discord_integrations = { 
            enabledApp,
            guildId,
            botToken,
            createMember,
            restrictMember,
            addRoletoMember,
            assignRoleAsTags,
            assignTagsAsRole,
            removeRoleOnExpire,
            assignRolesToExistingOrders,
            assignRolesToOrdersAmount,
        }

        const discord_linked = { 
            enableLinkButton,
            elementIdLinkButton,
            nameLinkButton,
            widthLinkButton,
            heightLinkButton,
            curveLinkButton,
            marginLinkButton,
            disableLinkButtonLogin,
            discordEmailLink,
        }

        const discord_invite = { 
            enableInvite,
            inviteLink,
            elementIdInvite,
            nameInviteButton,
            heightInviteButton,
            widthInviteButton,
            curveInviteButton,
            marginInviteButton
        }
      
        setActive((active) => !active);
        const response = await fetch(`/api/update_config?shop=${storeDomain}`, {
            method: "POST", 
            headers: {
                'Content-Type': 'application/json',
                'x-shopify-shop-domain': storeDomain
            },
            body: JSON.stringify({discord_integrations, discord_linked, discord_invite}),
        });

        if (response.ok) {
            console.log(response.status);
        } else {
            console.error('Failed to save settings');
        }
        setSaving(false);
        return;
    };

    const discordLinkButtonStyle = {
        display: 'flex',
        alignItems: 'center',
        padding: "12px 18px",
        height: `${heightLinkButton}px`,
        width: `${widthLinkButton}px`,
        backgroundColor: "#7289DA",
        borderRadius: `${curveLinkButton}px`,
        color: "#FFFFFF",
        fontSize: `${heightLinkButton - 35}px`,
    };
    
    const discordLinkIconStyle = {
        height: `${heightLinkButton - 30}px`,
        width: `${heightLinkButton - 30}px`,
        marginRight: `${marginLinkButton}px`,
    };

    const discordInviteButtonStyle = {
        display: 'flex',
        alignItems: 'center',
        padding: "12px 18px",
        height: `${heightInviteButton}px`,
        width: `${widthInviteButton}px`,
        backgroundColor: "#7289DA",
        borderRadius: `${curveInviteButton}px`,
        color: "#FFFFFF",
        fontSize: `${heightInviteButton - 35}px`,
    };
    
    const discordInviteIconStyle = {
        height: `${heightInviteButton - 30}px`,
        width: `${heightInviteButton - 30}px`,
        marginRight: `${marginInviteButton}px`,
    };
    
    const copyButtonTextLink = copiedLink ? "Copied!" : "Copy Embed";
    const copyButtonTextInvite = copiedInvite ? "Copied!" : "Copy Embed";
    
    const copyToClipboardLinkButton = () => {
        const textToCopy = '<div id="discordLinkButton"></div>';
        navigator.clipboard.writeText(textToCopy)
        .then(() => {
            console.log('Text copied to clipboard:', textToCopy);
            setCopiedLink(true);
            setTimeout(() => {
            setCopiedLink(false);
            }, 2000);
        })
        .catch((error) => {
            console.error('Error copying text to clipboard:', error);
        });
    };
    
    const copyToClipboardInviteButton = () => {
        const textToCopy = '<div id="discordInviteButton"></div>';
        navigator.clipboard.writeText(textToCopy)
        .then(() => {
            console.log('Text copied to clipboard:', textToCopy);
            setCopiedInvite(true);
            setTimeout(() => {
            setCopiedInvite(false);
            }, 2000);
        })
        .catch((error) => {
            console.error('Error copying text to clipboard:', error);
        });
    };

    const toastMarkup = active ? (
        <Toast content="Saved Settings Successfully" onDismiss={handleDismiss} />
      ) : null;

    return (
        <div style={{height: '250px'}}>
            <Frame>
            <Page
                title="Member & Role Integration"
                subtitle="Allow users to connect to discord, create members and assign them a role"
                primaryAction={{content: saving ? <Spinner size="small" color="teal" /> : 'Save', onAction: handleSubmit}}
            >
                <Layout>
                    <Layout.AnnotatedSection
                        title="Discord Integrations"
                        description="This will allow permissions to interactive with discord server"
                    >
                    <Card sectioned>
                        <Form>
                        <FormLayout>
                            <Checkbox
                                label="Enable Discord Integration"
                                checked={enabledApp}
                                onChange={setEnabledApp}
                            />
                            <TextField
                                label="Enter Discord Guild ID"
                                value={guildId}
                                onChange={setGuildId}
                                placeholder="Guild ID"
                            />
                            <TextField
                                label="Enter Discord Bot Token"
                                value={botToken}
                                onChange={setBotToken}
                                placeholder="Bot Token"
                            />
                        </FormLayout>
                        </Form>
                    </Card>
                    </Layout.AnnotatedSection>
                    <Layout.AnnotatedSection
                        title="Add and Restrict Member on Login/Registration"
                        description="This feature will allow to add and restrict member form discord server"
                    >
                    <Card sectioned>
                        <Form>
                        <FormLayout>
                            <Checkbox 
                                label="Allow Customers to Join Discord Automatically on Registration."
                                checked={createMember} 
                                onChange={setCreateMember} 
                            />
                            <Checkbox 
                                label="Restirct New Users to Linked with Discord Except Existing Users." 
                                checked={restrictMember} 
                                onChange={setRestrictMember} 
                            />
                            <TextField
                                label="Assign Role to Member on Registration"
                                value={addRoletoMember}
                                onChange={setAddRoletoMember}
                                placeholder="Enter Role ID"
                            />
                        </FormLayout>
                        </Form>
                    </Card>
                    </Layout.AnnotatedSection>
                    <Layout.AnnotatedSection
                        title="Assign Roles to Discord Members According to Customer Tags"
                        description="This feature will allow to add and remove role of member"
                    >
                    <Card sectioned>
                        <Form>
                        <FormLayout>
                            <Checkbox 
                                label="Add Roles to Discord Members Based on Customers Tags."
                                checked={assignRoleAsTags}
                                onChange={setAssignRoleAsTags} 
                            />
                            <Checkbox 
                                label="Add Tags to Store Customers Based on Members Roles." 
                                checked={assignTagsAsRole} 
                                onChange={setAssignTagsAsRole} 
                            />
                            <Checkbox 
                                label="If a Subscription Expires, Remove the Discord Members Roles (Product Integration)." 
                                checked={removeRoleOnExpire} 
                                onChange={setRemoveRoleOnExpire} 
                            />
                            <Checkbox 
                                label="Assign Roles to Existing Customer Orders (Product Integration)." 
                                checked={assignRolesToExistingOrders} 
                                onChange={setAssignRolesToExistingOrders} 
                            />
                            <Checkbox 
                                label="Assign Roles Based on Orders Amount (Orders Integration)." 
                                checked={assignRolesToOrdersAmount} 
                                onChange={setAssignRolesToOrdersAmount} 
                            />
                        </FormLayout>
                        </Form>
                    </Card>
                    </Layout.AnnotatedSection>
                    <Layout.AnnotatedSection
                        title="Link Member with Discord Button"
                        description="Enter the Discord Account Link Button Configuration."
                    >
                    <Card sectioned>
                    <FormLayout>
                        <Checkbox
                            label="Enable Linked with Discord"
                            checked={enableLinkButton}
                            onChange={setEnableLinkButton}
                        />
                        {enableLinkButton && (
                        <Collapsible
                            open={enableLinkButton}
                            id="collapsible-checkbox-content"
                            transition={{duration: '150ms', timingFunction: 'ease'}}
                        >
                            <FormLayout>
                            <div style={{ display: 'flex', gap: '50px' }}>
                                <div style={discordLinkButtonStyle}>
                                    <Image src={DiscordIcon} alt="Discord Linked" style={discordLinkIconStyle} />
                                    {nameLinkButton}
                                </div>
                                <Button variant="primary" onClick={copyToClipboardLinkButton}>{copyButtonTextLink}</Button>
                            </div>
                                <TextField
                                    label="Element ID Form Your Theme Store"
                                    value={elementIdLinkButton}
                                    onChange={setElementIdLinkButton}
                                />
                                <TextField
                                    label="Name"
                                    value={nameLinkButton}
                                    onChange={setNameLinkButton}
                                />
                            <div style={{ display: 'flex', gap: '25px' }}>
                                <TextField
                                    label="Height (in pixel)"
                                    type="number"
                                    value={heightLinkButton}
                                    onChange={setHeightLinkButton}
                                />
                                <TextField
                                    label="Width (in pixel)"
                                    type="number"
                                    value={widthLinkButton}
                                    onChange={setWidthLinkButton}
                                />
                                <TextField
                                    label="Curve (in pixel)"
                                    type="number"
                                    value={curveLinkButton}
                                    onChange={setCurveLinkButton}
                                />
                                <TextField
                                    label="Margin (in pixel)"
                                    type="number"
                                    value={marginLinkButton}
                                    onChange={setMarginLinkButton}
                                />
                            </div>
                            <div style={{ display: 'flex', gap: '25px' }}>
                                <Checkbox
                                    label="Don't allow user login on Link with Discord"
                                    checked={disableLinkButtonLogin}
                                    onChange={setDisableLinkButtonLogin}
                                />
                            </div>
                            <div style={{ display: 'flex', gap: '25px' }}>
                                <Checkbox
                                    label="Allow to update Email address on Link with Discord"
                                    checked={discordEmailLink}
                                    onChange={setDiscordEmailLink}
                                />
                            </div>
                            </FormLayout>
                        </Collapsible>
                        )}
                    </FormLayout>
                    </Card>
                </Layout.AnnotatedSection>
                <Layout.AnnotatedSection
                    title="Invite Member with Discord Button"
                    description="Enter the Discord Invite Link Button Configuration."
                >
                    <Card sectioned>
                    <FormLayout>
                        <Checkbox
                            label="Enable Join us on Discord"
                            checked={enableInvite}
                            onChange={setEnableInvite}
                        />
                        {enableInvite && (
                        <Collapsible
                            open={enableInvite}
                            id="collapsible-checkbox-content"
                            transition={{duration: '150ms', timingFunction: 'ease'}}
                        >
                            <FormLayout>
                            <div style={{ display: 'flex', gap: '50px' }}>
                            <div style={discordInviteButtonStyle}>
                                <Image src={DiscordIcon} alt="Discord Invite" style={discordInviteIconStyle} />
                                {nameInviteButton}
                            </div>
                            <Button variant="primary" onClick={copyToClipboardInviteButton}>{copyButtonTextInvite}</Button>
                            </div>
                            <TextField
                                label="Discord Invite Link"
                                value={inviteLink}
                                onChange={setInviteLink}
                            />
                            <TextField
                                label="Element ID form your theme store"
                                value={elementIdInvite}
                                onChange={setElementIdInvite}
                            />
                            <TextField
                                label="Button Name"
                                value={nameInviteButton}
                                onChange={setNameInviteButton}
                            />
                            <div style={{ display: 'flex', gap: '25px' }}>
                            <TextField
                                label="Height (in pixel)"
                                type="number"
                                value={heightInviteButton}
                                onChange={setHeightInviteButton}
                            />
                            <TextField
                                label="Width (in pixel)"
                                type="number"
                                value={widthInviteButton}
                                onChange={setWidthInviteButton}
                            />
                            <TextField
                                label="Curve (in pixel)"
                                type="number"
                                value={curveInviteButton}
                                onChange={setCurveInviteButton}
                            />
                            <TextField
                                label="Margin (in pixel)"
                                type="number"
                                value={marginInviteButton}
                                onChange={setMarginInviteButton}
                            />
                            </div>
                        </FormLayout>
                        </Collapsible>
                    )}
                    </FormLayout>
                    </Card>
                </Layout.AnnotatedSection>
                </Layout>
                {toastMarkup}
                <Page></Page>
                <Page></Page>
                <Page></Page>
            </Page>
            </Frame>
        </div>
    );
};
